<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="auto"
                class="text-h6"
              >
                {{ $t('Accounting Actions') }}
              </v-col>

              <v-col
                cols="12"
                sm="auto"
              >
                <app-date-picker
                  v-model="queryParams.start_date"
                  :label="$t('Start Date')"
                  no-title
                  hide-details
                />
              </v-col>

              <v-col
                cols="12"
                sm="auto"
              >
                <app-date-picker
                  v-model="queryParams.end_date"
                  :label="$t('End Date')"
                  no-title
                  hide-details
                />
              </v-col>

              <v-col
                cols="auto"
                sm="2"
                md="auto"
              >
                <v-btn
                  color="primary"
                  depressed
                  small
                  :loading="loading"
                  @click="getAccStmtData()"
                >
                  {{ $t('Search') }}
                </v-btn>
              </v-col>

              <v-col cols="auto">
                <v-btn
                  depressed
                  small
                  :class="{
                    'black white--text': !$vuetify.theme.dark,
                    'white black--text': $vuetify.theme.dark,
                  }"
                  @click="$_print()"
                >
                  <v-icon left>
                    mdil-printer
                  </v-icon>

                  {{ $t('Print') }}
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                sm
              >
                <v-row
                  class="justify-sm-end"
                  dense
                >
                  <v-col cols="auto">
                    <v-btn
                      color="primary"
                      small
                      depressed
                      @click="addDebit()"
                    >
                      {{ $t('Transaction Price') }}
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      color="error"
                      small
                      depressed
                      @click="addExpense()"
                    >
                      {{ $t('Expense') }}
                    </v-btn>
                  </v-col>

                  <v-col cols="auto">
                    <v-btn
                      color="green darken-2"
                      class="white--text"
                      small
                      depressed
                      @click="addReceipt()"
                    >
                      {{ $t('Add receipt') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-simple-table
                  id="print-area"
                  class="print-table"
                >
                  <template #default>
                    <thead>
                      <tr>
                        <invoice-header />
                      </tr>

                      <tr>
                        <th
                          colspan="100"
                          class="text-subtitle-1 d-none d-print-table-cell font-weight-bold black--text"
                          style="height: 30px"
                        >
                          <table style="width: 100%">
                            <tr>
                              <th class="text-start">
                                {{ $t('Factory & Occupation Name') }}: {{ factory.name_ckb }}
                              </th>
                              <th
                                v-if="factory.owner_type"
                                class="text-center"
                              >
                                {{ $t('Owner') }}: {{ factory.owner_type == 1 ? factory.shareholder.username : factory.company.name }}
                              </th>
                              <th class="text-center">
                                {{ $t('Date') }}: {{ queryParams.start_date }} - {{ queryParams.end_date }}
                              </th>
                            </tr>
                          </table>
                        </th>
                      </tr>

                      <tr>
                        <th>#</th>
                        <th>{{ $t('Date') }}</th>
                        <th>{{ $t('Type') }}</th>
                        <th>{{ $t('Invoice No') }}</th>
                        <th class="d-print-none">
                          {{ $t('Invoice Picture') }}
                        </th>
                        <th>{{ $t('Description') }}</th>
                        <th>{{ $t('Credit') }}</th>
                        <th>{{ $t('Debt') }}</th>
                        <th>{{ $t('Balance') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(row, accIndex) in apiData.data"
                        :key="accIndex"
                        :class="{
                          'pointer': accIndex > 0,
                        }"
                        @click="updateRow(row, accIndex)"
                      >
                        <template v-if="accIndex == 0">
                          <td
                            colspan="5"
                            class="text-center"
                          >
                            {{ $t('Opening Balance') }}
                          </td>
                          <td class="d-print-none" />
                          <td>{{ $_number_format(row.credit) }}</td>
                          <td>{{ $_number_format(row.debit) }}</td>
                          <td>{{ $_number_format(row.balance) }}</td>
                        </template>

                        <template v-else>
                          <td>{{ accIndex }}</td>
                          <td
                            class="dir-ltr"
                            :class="{
                              'text-end': $vuetify.rtl,
                              'text-start': !$vuetify.rtl,
                            }"
                          >
                            {{ $_date_format(row.datetime) }}
                          </td>
                          <td>
                            <v-chip
                              label
                              small
                              :color="getChipColor(row.action_type)"
                            >
                              {{ $t(`accounting.${row.action_type}`) }}
                            </v-chip>
                          </td>
                          <td>{{ row.invoice_no }}</td>
                          <td class="d-print-none">
                            <template v-if="row.attachments.length">
                              <div style="max-width: 150px">
                                <div class="d-flex flex-wrap">
                                  <a
                                    v-for="(attachment, index) in row.attachments"
                                    :key="index"
                                    :href="`${$_file_path()}${attachment.attachment}?token=${$_auth_token()}`"
                                    target="_blank"
                                    class="me-2"
                                    @click.stop
                                  >
                                    <v-icon>mdil-eye</v-icon>
                                  </a>
                                </div>
                              </div>
                            </template>
                          </td>
                          <td>
                            <span
                              class="d-inline-block d-md-none d-print-none text-truncate"
                              style="max-width: 200px;"
                            >
                              {{ row.description }}
                            </span>
                            <span class="d-none d-print-inline-block d-md-inline-block">
                              {{ row.description }}
                            </span>
                          </td>
                          <td>{{ $_number_format(row.credit) }}</td>
                          <td>{{ $_number_format(row.debit) }}</td>
                          <td>{{ $_number_format(row.balance) }}</td>
                        </template>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr
                        v-if="apiData.totals"
                        class="font-weight-bold grey"
                        :class="{
                          'darken-3': $vuetify.theme.dark,
                          'lighten-3': !$vuetify.theme.dark,
                        }"
                      >
                        <td colspan="5" />
                        <td class="d-print-none" />
                        <td>{{ $_number_format(apiData.totals.credit) }}</td>
                        <td>{{ $_number_format(apiData.totals.debit) }}</td>
                        <td>{{ $_number_format(apiData.totals.balance) }}</td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <add-edit
      :form-data="formData"
      :add-edit-dialog="addEditDialog"
      :title="title"
      :url="url"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @saved="getInitData()"
    />
  </div>
</template>

<script>
import axios from 'axios'
import AddEdit from './AddEdit.vue'
import { mapActions } from 'vuex'
import Vue from 'vue'

export default {
  components: {
    AddEdit
  },

  data() {
    return {
      factory: {},
      apiData: {},
      debits: [],
      formData: {},
      editMode: false,
      addEditDialog: false,
      loading: false,
      url: '',
      title: '',
      queryParams: {
        label: 'factory',
        model_name: 'factory',
        model_id: this.$route.params.id,
        start_date: this.$_date_for_picker(Vue.moment().startOf('year').format('YYYY-MM-DD')),
        end_date: this.$_date_for_picker(),
      },
    }
  },

  mounted() {
    this.getViewData()
    this.getInitData()
  },

  methods: {
    ...mapActions([
      'getExpenseTypeList'
    ]),

    async getViewData() {
      await axios.get(`factory/factory/${this.$route.params.id}/`).then(res => {
        this.factory = res.data
      })
    },

    getInitData() {
      this.getExpenseTypeList()
      this.getAccStmtData()
    },

    getChipColor(type) {
      if (type == 'Expense') return 'error'
      if (type == 'Receipt') return 'success'
      if (type == 'Debit') return 'primary'
    },

    addDebit() {
      this.editMode = false
      this.formData = {
        amount: null,
        label: 'factory',
        model_name: 'factory',
        model_id: Number(this.$route.params.id),
        action_date: this.$_date_for_picker(),
        currency: 'IQD',
        attachments: [],
      }
      this.title = this.$t('Add Transaction Price')
      this.url = 'debit/'
      this.addEditDialog = true
    },

    addReceipt() {
      this.editMode = false
      this.formData = {
        amount: null,
        label: 'factory',
        model_name: 'factory',
        model_id: Number(this.$route.params.id),
        action_date: this.$_date_for_picker(),
        currency: 'IQD',
        type: 2,
        attachments: [],
      }
      this.title = this.$t('Add receipt')
      this.url = 'receipt/'
      this.addEditDialog = true
    },

    addExpense() {
      this.editMode = false
      this.formData = {
        amount: null,
        label: 'factory',
        model_name: 'factory',
        model_id: Number(this.$route.params.id),
        action_date: this.$_date_for_picker(),
        currency: 'IQD',
        attachments: [],
      }
      this.title = this.$t('Add expense')
      this.url = 'expense/'
      this.addEditDialog = true
    },

    async updateRow(row, index) {
      if (index == 0) return

      this.formData = { ...row }

      if (row.action_type == 'Expense') {
        this.title = this.$t('Update expense')
        this.url = `expense/${row.id}/`
        this.formData.expense_type_id = { text: row.expense_type.name, value: row.expense_type.id }
      }
      if (row.action_type == 'Receipt') {
        this.title = this.$t('Update receipt')
        this.url = `receipt/${row.id}/`
      }
      if (row.action_type == 'Debit') {
        this.title = this.$t('Update debit')
        this.url = `debit/${row.id}/`
      }

      if (this.formData.attachments.length) {
        await this.formData.attachments.forEach(attach => {
          if (!attach.attachment.includes(this.$_file_path())) {
            attach.attachment = `${this.$_file_path()}${attach.attachment}?token=${this.$_auth_token()}`
          }
        })
      }
      this.editMode = true
      this.addEditDialog = true
    },

    getAccStmtData() {
      this.loading = true

      axios.post('report/account-statement', this.queryParams).then(res => {
        this.apiData = res.data
      }).finally(() => {
        this.loading = false
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>